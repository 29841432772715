/* eslint-disable @typescript-eslint/no-empty-function */
import { DataSource, CollectionViewer } from '@angular/cdk/collections'
import { Subscription, Observable, firstValueFrom } from 'rxjs'
import { digest } from 'object-sha'
import { concatLatestFrom } from '@ngrx/operators'

export class GenericDataSourceForFiltersNoCache<
  TCollection,
  TFilters extends {
    page: number
    itemsPerPage: number
  }
> extends DataSource<TCollection> {
  private _pageSize = 25
  private _lastFiltersSha = ''
  private _fetchedPages: {
    [key: string]: Set<number>
  } = {}
  private _subscription: Subscription = new Subscription()

  private readonly filters$: Observable<TFilters>

  private collection$: Observable<TCollection[]>
  private loadCollectionCallback: (filters: TFilters) => void
  private clearCollectionCallback: () => void
  private patchPageCallback: (newPage: number) => void

  constructor(
    collection$: Observable<TCollection[]>,
    loadCollectionCallback: (filters: TFilters) => void,
    filters$: Observable<TFilters>,
    clearCollectionCallback: () => void = () => {},
    patchPageCallback: (newPage: number) => void = () => {}
  ) {
    super()
    this.collection$ = collection$
    this.loadCollectionCallback = loadCollectionCallback
    this.clearCollectionCallback = clearCollectionCallback
    this.patchPageCallback = patchPageCallback
    this.filters$ = filters$
    firstValueFrom(this.filters$).then(filters => {
      this._pageSize = filters.itemsPerPage
    })
  }

  get dataStream$() {
    return this.collection$
  }

  setPageSize(pageSize: number) {
    this._pageSize = pageSize
  }

  connect(collectionViewer: CollectionViewer): Observable<TCollection[]> {
    this._subscription.add(
      collectionViewer.viewChange
        .pipe(concatLatestFrom(() => this.filters$))
        .subscribe(async ([range, filters]) => {
          const startPage = this.getPageForIndex(range.start)
          const endPage = this.getPageForIndex(range.end + 1)
          for (let i = startPage; i <= endPage; i++) {
            await this._fetchPage(i, filters)
          }
        })
    )

    this._subscription.add(
      this.filters$.subscribe(async filters => {
        const filtersSha = await digest({ ...filters, page: Infinity })
        if (this._lastFiltersSha === filtersSha) return
        this._fetchedPages = {}
        this.clearCollectionCallback()
        this.patchPageCallback(1)
        this._fetchPage(0, filters)
      })
    )

    return this.collection$
  }

  disconnect(): void {
    this._subscription.unsubscribe()
    this._subscription = new Subscription()
  }

  private getPageForIndex(index: number): number {
    return Math.max(Math.floor(index / this._pageSize), 0)
  }

  private async _fetchPage(page: number, filters: TFilters) {
    const filtersSha = await digest({ ...filters, page: Infinity })

    this._lastFiltersSha = filtersSha
    if (!this._fetchedPages[filtersSha])
      this._fetchedPages[filtersSha] = new Set<number>()

    if (this._fetchedPages[filtersSha].has(page)) return

    this._fetchedPages[filtersSha].add(page)
    this.loadCollectionCallback({
      ...filters,
      page: page + 1
    })
  }
}
