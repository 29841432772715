import { DataSource, CollectionViewer } from '@angular/cdk/collections'
import {
  BehaviorSubject,
  Subscription,
  Observable,
  combineLatest,
  firstValueFrom,
  skip
} from 'rxjs'
import { digest } from 'object-sha'

export class GenericDataSourceForFilters<
  TCollection,
  TFilters extends {
    page: number
    itemsPerPage: number
  }
> extends DataSource<TCollection> {
  private _pageSize = 25
  private _cachedData: { [key: string]: TCollection[] } = {}
  private _fetchedPages: {
    [key: string]: Set<number>
  } = {}
  private _subscription: Subscription = new Subscription()

  private readonly _dataStream = new BehaviorSubject<TCollection[]>([])
  private readonly filters$: Observable<TFilters>

  private collection$: Observable<TCollection[]>
  private loadCollectionCallback: (filters: TFilters) => void

  constructor(
    collection$: Observable<TCollection[]>,
    loadCollectionCallback: (filters: TFilters) => void,
    filters$: Observable<TFilters>
  ) {
    super()
    this.collection$ = collection$
    this.loadCollectionCallback = loadCollectionCallback
    this.filters$ = filters$
    firstValueFrom(this.filters$).then(filters => {
      this._pageSize = filters.itemsPerPage
    })
  }

  get dataStream$() {
    return this._dataStream
  }

  connect(collectionViewer: CollectionViewer): Observable<TCollection[]> {
    this._subscription.add(
      combineLatest([collectionViewer.viewChange, this.filters$]).subscribe(
        async ([range, filters]) => {
          const startPage = this.getPageForIndex(range.start)
          const endPage = this.getPageForIndex(range.end + 1)
          for (let i = startPage; i <= endPage; i++) {
            await this._fetchPage(i, filters)
          }
        }
      )
    )

    firstValueFrom(this.filters$).then(filters => {
      this._fetchPage(0, filters)
    })
    return this._dataStream
  }

  disconnect(): void {
    this._subscription.unsubscribe()
    this._subscription = new Subscription()
  }

  private getPageForIndex(index: number): number {
    return Math.max(Math.floor(index / this._pageSize), 0)
  }

  private async _fetchPage(page: number, filters: TFilters) {
    const filtersSha = await digest({ ...filters, page: Infinity })
    if (!this._fetchedPages[filtersSha]) {
      this._fetchedPages[filtersSha] = new Set<number>()
    }

    if (!this._cachedData[filtersSha]) {
      this._cachedData[filtersSha] = []
    }
    if (this._fetchedPages[filtersSha].has(page)) {
      this._dataStream.next(this._cachedData[filtersSha])
      return
    }
    this._fetchedPages[filtersSha].add(page)

    this.loadCollectionCallback({
      ...filters,
      page: page + 1
    })

    firstValueFrom(this.collection$.pipe(skip(1))).then(items => {
      this._cachedData[filtersSha].splice(
        page * this._pageSize,
        this._pageSize,
        ...items
      )
      this._dataStream.next(this._cachedData[filtersSha])
    })

    console.log('this._cachedData', filtersSha)
  }
}
