function r(r) {
  return null != r && "object" == typeof r && !Array.isArray(r);
}
function n(t) {
  return r(t) || Array.isArray(t) ? Array.isArray(t) ? t.map(t => Array.isArray(t) || r(t) ? n(t) : t) : Object.keys(t).sort().map(r => [r, n(t[r])]) : t;
}
function t(r) {
  return JSON.stringify(n(r));
}
function e(r, n = "SHA-256") {
  const e = ["SHA-1", "SHA-256", "SHA-384", "SHA-512"];
  if (!e.includes(n)) throw RangeError(`Valid hash algorithm values are any of ${JSON.stringify(e)}`);
  return async function (r, n) {
    const e = new TextEncoder().encode(t(r)).buffer;
    let a = "";
    {
      const r = await crypto.subtle.digest(n, e),
        t = "0123456789abcdef";
      new Uint8Array(r).forEach(r => {
        a += t[r >> 4] + t[15 & r];
      });
    }
    return a;
  }(r, n);
}
export { e as digest, t as hashable };
