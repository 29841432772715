export enum KnownPermissions {
  InvoicesRead = 'read:apis',
  InvoicesCreate = 'create:apis',
  InvoicesMassActions = 'execute:invoiceapi:mass-actions',
  InvoicesMassApprove = 'execute:invoiceapi:mass-approve',
  OrderReadPermission = 'read:orderapi',
  ReportsReadPermission = 'read:identityapi:reports',
  ReportsCreatePermission = 'create:identityapi:reports',
  ReportsUpdatePermission = 'update:identityapi:reports',
  ReportsDeletePermission = 'delete:identityapi:reports',
  DocumentsReadPermission = 'read:unmatcheddocumentapi',
  TenantsReadPermission = 'read:identityapi:tenants',
  CustomersReadPermission = 'read:customerapi',
  VendorsReadPermission = 'read:vendorapi',
  UsersReadPermission = 'read:identityapi:users',
  RolesReadPermission = 'read:identityapi:roles',
  AutomationReadPermission = 'read:identityapi:automations',
  ChargeCodesReadPermission = 'read:identityapi:chargecodes',
  DisputesReadPermission = 'read:identityapi:disputeapi',
  DeletePermission = 'delete:apis'
}
