export function parseDate(stringDate: string | undefined | null) {
  if (stringDate === undefined || stringDate === null) return undefined
  const localeDate = new Date(stringDate).toLocaleDateString('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })

  return new Date(localeDate)
}
